import '@/scss/_reset.scss'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import Utils from '@/classes/Utils'
const utils = new Utils(process.env.VUE_APP_IS_DEBUG)
Vue.prototype.$utils = utils

import Api from '@/classes/Api'
const api = new Api()
Vue.prototype.$api = api

//import Consts from '@/classes/Consts'
//const consts = new Consts()
//Vue.prototype.$consts = consts

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
