<template>
	<div class="service-item-wrapper">
		<div class="item">
			<div class="item-number">第<span class="item-numbertext" :style="{ 'color': data.color }">{{ data.number }}</span>弾</div>
			<div class="item-category">{{ data.category }}</div>
			<div class="hr"></div>
			<div class="item-title" :style="{ 'color': data.color }">{{ data.title }}</div>
			<div class="item-schedule">{{ data.schedule }}</div>
			<div class="item-description">{{ data.description }}</div>
			<div class="item-name" :class="{ 'temp': data.isTemp }" :style="getStyle(data.isTemp)">{{ data.name }}</div>
		</div>
		<div v-if="data.isNext" class="next _sp"></div>
	</div>
</template>

<style lang="scss" scoped>
@import "@/scss/style.scss";

.item {
	border: 2px #000 solid;
	background: #fff;
	padding: 5px;
	font-weight: bold;
	line-height: 1.2;

	&-number {
		@include flex($justify: center, $align: center);
		font-size: 2rem;
	}

	&-numbertext {
		font-family: 'Anton', sans-serif;
		font-size: 7rem;
		margin: 0 5px;
	}

	&-category {
		font-size: 2rem;
	}

	&-title {
		font-size: 3.4rem;
	}

	&-description {
		font-weight: normal;
	}

	&-schedule {
		color: red;
		font-size: 1rem;
		font-weight: normal;
		margin: 2px 0 10px 0;
	}

	&-name {
		margin: 3px 0 8px;
		font-size: 2.5rem;
	}

	.temp::after {
		content: ' (仮)';
		font-size: 1.2rem;
	}

	.hr {
		@include hr;
		margin: 10px auto 6px;
	}
}

.next {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 20px 20px 0 20px;
	border-color: #000000 transparent transparent transparent;
	margin: 0 auto 5px;
}

.service-item-wrapper {
	width: 100%;
	opacity: 0;
}
.fade {
	animation: fadeIn .2s ease-in forwards;
}

@keyframes fadeIn {
	0% { opacity: 0; }
	70% { opacity: .2; }
	100% { opacity: 1; }
}
</style>

<script>

export default {
	name: 'ServiceItem',
	props: {
		data: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
		}
	},
	mounted() {
	},
	computed: {
	},
	methods: {
		getStyle: function(isTemp) {
			let ml = isTemp ? '22px' : '0';
			let style = {
				'margin-left': ml,
			};

			return style;
		},
	},
}
</script>
