<template>
	<div class="home">
		<div class="wrapper">
			<img src="../assets/home/eyecatch.svg" alt="50代60代を応援したい">

			<button type="button" class="button" @click="onToFormButtonClick">登録する(無料)</button>

			<div class="description-wrapper">
				<h2>もう一度気持ちを奮い立たせてください</h2>
				<div>人生100年時代、約3,000万人のオトナ達(50～60代)が、折り返して次のステージを走り始めています。昨今、新型コロナウイルスの流行による労働環境の悪化、終身雇用の崩壊、8050問題など、オトナ達は大きな困難に直面していて、孤独を感じ、自信を失くしています。<br><br>しかし思い出してください。<br>かつて一丸となって高度経済成長を支えたことを。今の日本をここまで引っ張ってきたことを。</div>
			</div>

			<img src="../assets/home/eyecatch_2.svg" alt="未来あるオトナ達が、より自分らしく生きられる社会を創る">

			<img src="../assets/home/eyecatch_platform.svg" alt="">

			<ul id="service-list" class="services">
				<li v-for="row in services" :key="row.name">
					<service-item :data="row"></service-item>
				</li>
			</ul>

			<img src="../assets/home/eyecatch_2_2.svg" alt="">

			<div class="description-wrapper eyecatch_2_2_margin">
				<h2>もっともっと人生を楽しみませんか！？</h2>
				<div>これからは生き方も多様化が進み、「個」の時代になるといわれています。<br><br>「個」だけど「孤」じゃない。<br>そんな時代にもう一度、オトナ達が一枚岩となって日本を盛り上げてほしい、人生を楽しんでほしいと、私たちは考えています。<br><br>オトナ達に、より自分らしく、前向きで、ROCKな人生を歩んでほしいとの想いから、サービス名を「GOROCK!&nbsp;(50-69)」と名付けました。</div>
			</div>

			<div class="hr"></div>

			<div class="skillshare">
				<img class="_pc" style="margin-top: -50px;" src="../assets/home/eyecatch_skillshare_pc.svg" alt="第1弾スキルシェア">
				<img class="_sp" style="margin-top: -50px;" src="../assets/home/eyecatch_skillshare_sp.svg" alt="第1弾スキルシェア">

				<h2 style="margin-top: 0px;">アナタの経験、知識は必ず誰かの<br class="_sp">助けになる！<br class="_pc">スキルを販売して、<br class="_sp">困っている人を応援しよう！</h2>

				<div class="skillshare-title">登録は２パターン</div>
				<div class="skillshare-description">登録方法は2パターンをご用意。<br class="_sp">目的に応じて選択可能です。</div>
				<img class="skillshare-image _pc" src="../assets/home/skillshare_1_pc.svg" alt="スキルシェアイメージ図">
				<img class="skillshare-image _pc" src="../assets/home/skillshare_2_pc.svg" alt="求人イメージ図">
				<img class="skillshare-image _sp" src="../assets/home/skillshare_1_sp.svg" alt="スキルシェアイメージ図">
				<img class="skillshare-image _sp" src="../assets/home/skillshare_2_sp.svg" alt="求人イメージ図">
			</div>

			<div class="hr"></div>

			<div class="gocon">
				<img src="../assets/home/eyecatch_gocon.svg" style="margin-top: -52px;" alt="第2弾GOCON">
				<h2 class="gocon-description">なかなか出会いが無い。<br class="_sp">離婚したけどまた<br class="_pc">良い人と出会いたい。<br class="_sp">婚活したい。<br class="_pc">GOROCK!は真剣に<br class="_sp">お相手を探している<br class="_pc">男女も応援します！</h2>
			</div>

			<div class="hr"></div>

			<div class="feature">
				<div class="feature-title">GOROCK!の特徴</div>
				<ul class="feature-list">
					<li>
						<div>50代60代が<br>中心</div>
						<div>会員は知識と経験豊富な50代60代が中心です。同年代なので気軽に取引ができます。</div>
						<img src="../assets/home/feature_oyaji.svg">
					</li>

					<li>
						<div>短時間から<br>利用OK!</div>
						<div>時間にしばられることなく、好きな日に好きな時間だけ仕事ができます。</div>
						<img src="../assets/home/feature_time.svg">
					</li>

					<li>
						<div>安心の<br>エスクロー決済</div>
						<div>料金の支払いは運営側が仲介。取引完了後に双方の承諾をもって支払われるため安心です。</div>
						<img src="../assets/home/feature_money.svg">
					</li>

					<li>
						<div>評価精度で安全</div>
						<div>知らない相手でも安全に取引できるように、相互評価制度を導入。事前にレビューを見て、納得した上で取引が可能です。</div>
						<img src="../assets/home/feature_check.svg">
					</li>

					<li>
						<div>求人代わりに</div>
						<div>スポットではなく、短期～長期のお仕事を探している人も。50代60代専用の求人情報があります。</div>
						<img src="../assets/home/feature_job.svg">
					</li>

					<li>
						<div>新しい出会いを<br>求める人を応援</div>
						<div>離婚してしまってからはなかなか出会いが無い人。もう一度真剣な恋を始めたい人を応援！</div>
						<img src="../assets/home/feature_heart.svg">
					</li>
				</ul>
			</div>

			<div class="hr"></div>

			<div ref="entryForm" class="form-eyecatch">
				<img v-show="isCheckMarkShow" class="checkmark" src="../assets/home/check.svg">
				<img src="../assets/home/eyecatch_form.svg" alt="応募フォーム">
			</div>

			<div class="formarea">
				<div class="formarea-titlearea">
					<div class="formarea-title">応募フォーム</div>
					<div class="formarea-description">2021年春リリース予定のGOROCK!アプリ先行登録者様を募集中！抽選で100名様にオリジナルTシャツプレゼント！アプリ本登録完了後、発送させて頂きます。</div>
				</div>

				<form class="form">
					<dl>
						<dt class="form-label require _pc">お名前</dt>
						<dd>
							<div class="form-field name">
								<div>
									<span class="form-label require sp">姓</span>
									<span class="form-label pc">姓</span>
									<input type="text" v-model="nameSei" :class="{ 'is-danger': $utils.hasError(errors, 'nameSei') }" @blur="isNameSeiValid" @keyup="isNameSeiValid"/>
								</div>
								<div>
									<span class="form-label require sp">名</span>
									<span class="form-label pc">名</span>
									<input type="text" v-model="nameMei" :class="{ 'is-danger': $utils.hasError(errors, 'nameMei') }" @blur="isNameMeiValid" @keyup="isNameMeiValid"/>
								</div>
							</div>
							<p v-show="$utils.hasError(errors, 'nameSei')" class="is-danger">{{ errors.nameSei }}</p>
							<p v-show="$utils.hasError(errors, 'nameMei')" class="is-danger">{{ errors.nameMei }}</p>
						</dd>
					</dl>

					<dl>
						<dt class="form-label require">メールアドレス</dt>
						<dd>
							<div class="form-field">
								<input type="text" v-model="email" :class="{ 'is-danger': $utils.hasError(errors, 'email') }" @blur="isEmailValid" @keyup="isEmailValid"/>
							</div>
							<p v-show="$utils.hasError(errors, 'email')" class="is-danger">{{ errors.email }}</p>
						</dd>
					</dl>

					<dl>
						<dt class="form-label require">メールアドレス(確認)</dt>
						<dd>
							<div class="form-field">
								<input type="text" v-model="emailConfirm" :class="{ 'is-danger': $utils.hasError(errors, 'emailConfirm') }" @blur="isEmailConfirmValid" @keyup="isEmailConfirmValid"/>
							</div>
							<p v-show="$utils.hasError(errors, 'emailConfirm')" class="is-danger">{{ errors.emailConfirm }}</p>
						</dd>
					</dl>

					<dl>
						<dt class="form-label require">生年月日</dt>
						<dd>
							<div class="form-field">
								<select v-model="birthY">
									<option v-for="row in years" :key="row" :value="row.toString()">{{ row }}</option>
								</select>
								<span>年</span>

								<select v-model="birthM">
									<option v-for="n in 12" :key="n" :value="$utils.zeroPadding(n, 2)">{{ n }}</option>
								</select>
								<span>月</span>

								<select v-model="birthD">
									<option v-for="n in 31" :key="n" :value="$utils.zeroPadding(n, 2)">{{ n }}</option>
								</select>
								<span>日</span>
							</div>
						</dd>
					</dl>

					<dl>
						<dt class="form-label">ご意見・ご要望</dt>
						<dd>
							<div class="form-field">
								<textarea v-model="message"></textarea>
							</div>
						</dd>
					</dl>

					<div class="form-pp">プライバシーポリシー</div>
					<iframe src="./privacy-policy.html"></iframe>

					<div v-show="!canSubmit" class="form-disablebutton"><span class="strong">必須項目をすべて入力</span>すると<br>登録ボタンが表示されます</div>
					<div v-show="canSubmit" class="form-submitbutton button" @click="onSubmitClick">
						<div>
							<span class="agree">上記プライバシーポリシーに同意して</span>
							<div>登録する(無料)</div>
						</div>
					</div>
				</form>
			</div>

			<div class="hr"></div>

			<div class="youtube">
				<h2>GOROCK! YouTubeチャンネルも<br class="_sp">絶賛更新中！<br>チャンネル登録よろしくお願いします！</h2>
				<img src="../assets/home/youtube.png" alt="GOROCK!TV">
				<a href="https://www.youtube.com/channel/UChgqHrdJfV4IO7SGuBoUuEg" class="button" target="blank">YouTubeチャンネルへ</a>
			</div>

			<div class="hr"></div>

			<div class="tiger">
				<h2>人気YouTubeチャンネル<br>「令和の虎CHANNEL」にて<br class="_sp">放送されました！</h2>
				<span>チャンネル登録者数8.97万人</span><span class="tiger-countdate">(2020年12月21日現在)</span>
				<img src="../assets/home/tiger.svg" alt="令和の虎">
				<a href="https://www.youtube.com/watch?v=aQUQZ6HcIpQ&list=PLZs3A6Lcz_FRtBfSxpcFZcvHH3tGyfcCG" style="margin-top: 12px;" class="button" target="blank">YouTubeチャンネルへ</a>
			</div>

			<div class="hr"></div>

			<div class="contact">
				<h2>その他のお問い合わせは<br class="_sp">メールにて承っております。</h2>
				<p>お問い合わせ先メールアドレス：</p>
				<a :href="'mailto:' + contactMail">{{ contactMail }}</a>
			</div>
		</div>
	</div>
</template>

<script>
import ServiceItem from '@/components/ServiceItem'
import isMobile from 'ismobilejs'

export default {
	name: 'Home',
	components: {
		ServiceItem,
	},
	data () {
		return {
			nameSei: '',
			nameMei: '',
			email: '',
			emailConfirm: '',
			birthY: '1970',
			birthM: '01',
			birthD: '01',
			message: '',
			errors: {
				nameSei: '',
				nameMei: '',
				email: '',
				emailConfirm: '',
			},
			years: [],
			isNameSeiError: true,
			isNameMeiError: true,
			isEmailError: true,
			isEmailConfirmError: true,
			isCheckMarkShow: true,
			contactMail: 'info@gorock5069.com',
			services: [],
			serviceList: null,
		}
	},
	created() {
		for (let i = 1970; i >= 1951; i--) {
			this.years.push(i);
		}

		this.services = [
			{
				number: 1,
				category: 'お仕事マッチングサービス',
				title: 'スキルシェア',
				description: 'あなたの知識や得意なことをマッチング！',
				schedule: '2021年4月サービス開始予定',
				name: 'GOROCK!',
				color: '#25a2ef',
				isTemp: false,
				isNext: true,
			},
			{
				number: 2,
				category: '出会いマッチングサービス',
				title: '再婚・婚活・友人',
				description: 'パートナー探しや出会いを全力で応援します！',
				schedule: '2021年7月サービス開始予定',
				name: 'GOCON!',
				color: '#ef8a87',
				isTemp: true,
				isNext: true,
			},
			{
				number: 3,
				category: '会員店舗紹介サービス',
				title: 'ショップ紹介',
				description: '会員のお店と会員を繋いで売上に貢献します！',
				schedule: '2021年内サービス開始予定',
				name: 'SHOPGO!',
				color: '#f3d300',
				isTemp: true,
				isNext: true,
			},
			{
				number: 4,
				category: 'リアルマッチングサービス',
				title: 'コミュニティ',
				description: '趣味や飲み会など会員同士をリアルに繋ぐ！',
				schedule: '2021年内サービス開始予定',
				name: 'REALGO!',
				color: '#9fc238',
				isTemp: true,
				isNext: false,
			},
		];

		let self = this;
		setInterval(function() { self.isCheckMarkShow = !self.isCheckMarkShow }, 800)
	},
	mounted() {
		window.addEventListener('scroll', this.onWindowScroll);
		this.serviceList = document.getElementById('service-list');
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.onWindowScroll);
	},
	watch: {
	},
	computed: {
		canSubmit: function() {
			return !this.isNameSeiError && !this.isNameMeiError && !this.isEmailError && !this.isEmailConfirmError;
			//return true;
		}
	},
	methods: {
		addFadeClass: function(index) {
			this.serviceList.children[index].children[0].classList.add('fade');
		},
		onWindowScroll: function() {
			let target = window.pageYOffset + (window.innerHeight * 0.75);
			//this.$utils.log(target);
			if (this.serviceList.children[0].offsetTop > target) return;

			if (isMobile().any) {
				for (let i = 0; i < this.services.length; i++) {
					if (this.serviceList.children[i].offsetTop < target) {
						this.addFadeClass(i);
					}
				}
			} else {
				let self = this;
				for (let i = 0; i < this.services.length; i++) {
					setTimeout(this.addFadeClass, i * 400, i);
				}
			}
		},
		onToFormButtonClick: function() {
			window.scroll({ top: this.$refs.entryForm.offsetTop, behavior: "smooth" });
		},
		resetForm: function() {
			this.nameSei = '';
			this.nameMei = '';
			this.email = '';
			this.emailConfirm = '';
			this.message = '';
			this.birthY = '1970';
			this.birthM = '01';
			this.birthD = '01';
			this.isNameSeiError = true;
			this.isNameMeiError = true;
			this.isEmailError = true;
			this.isEmailConfirmError = true;
		},
		isNameSeiValid: function() {
			if (this.nameSei === '') {
				this.errors.nameSei = '姓を入力してください';
				this.isNameSeiError = true;
			} else {
				this.errors.nameSei = '';
				this.isNameSeiError = false;
			}
		},
		isNameMeiValid: function() {
			if (this.nameMei === '') {
				this.errors.nameMei = '名を入力してください';
				this.isNameMeiError = true;
			} else {
				this.errors.nameMei = '';
				this.isNameMeiError = false;
			}
		},
		isEmailValid: async function() {
			this.$utils.log(this.email);
			if (this.email === '') {
				this.errors.email = 'メールアドレスを入力してください';
				this.isEmailError = true;
				return;
			} else {
				this.errors.email = '';
				this.isEmailError = false;
			}
			
			if (!this.email.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
				this.errors.email = 'メールアドレスの形式ではありません';
				this.isEmailError = true;
				return;
			} else {
				this.errors.email = '';
				this.isEmailError = false;
			}

			try {
				let res = await this.$api.post('email_unique.php', { "email": this.email });
				this.$utils.log(res);

				if (!res.data.result) {
					this.errors.email = 'このメールアドレスは登録されています';
					this.isEmailError = true;
					return;
				} else {
					this.errors.email = '';
					this.isEmailError = false;

					this.isEmailConfirmValid();
				}
			} catch (error) {
				this.$utils.log(error);
				this.isEmailError = true;
				this.errors.email = '通信に失敗しました。しばらくたってから再度ご登録ください';
			}
		},
		isEmailConfirmValid: function() {
			if (this.email !== this.emailConfirm) {
				this.errors.emailConfirm = 'メールアドレスが一致しません';
				this.isEmailConfirmError = true;
			} else {
				this.errors.emailConfirm = '';
				this.isEmailConfirmError = false;
			}
		},
		onSubmitClick: async function() {
			if (!window.confirm('ご入力頂いた内容で応募します。よろしいですか？')) return false;

			let ep = 'registration.php';

			let data = {
				"name_sei": this.nameSei,
				"name_mei": this.nameMei,
				"email": this.email,
				"birthday": `${this.birthY}-${this.birthM}-${this.birthD}`,
				"message": this.message,
			};
			this.$utils.log(data);

			try {
				let res = await this.$api.post(ep, data);
				this.$utils.log(res);
				alert('ご登録が完了しました！\n\nご入力頂いたメールアドレス宛に確認のメールを送信しました。\n\nしばらくたっても届かない場合は[迷惑メール]フォルダに入っていないかご確認ください。');
				this.resetForm();
			} catch (error) {
				this.$utils.log(error);
				alert('通信に失敗しました。しばらくたってから再度ご登録ください');
			} finally {
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/scss/style.scss";
@import "@/scss/page/_home.scss";
</style>
