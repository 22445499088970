//import { ToastProgrammatic as Toast } from 'buefy'
import isMobile from 'ismobilejs'

class Utils {
	constructor(isDebug) {
		this.isDebug = isDebug;
	}

	log(str) {
		if (this.isDebug === 'true') {
			console.log(str);
		}
	}

	//_toast(msg, type) {
	//	Toast.open({
	//		duration: 3000,
	//		position: 'is-top',
	//		type: type,
	//		message: msg,
	//	});
	//}

	//toastSuccess(msg) {
	//	this._toast(msg, 'is-success');
	//}

	//toastError(msg) {
	//	this._toast(msg, 'is-danger');
	//}

	toCamelCase(str) {
		return str.split('_').map(function(word,index){
			if (index === 0) {
				return word.toLowerCase();
			}
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		}).join('');
	}

	zeroPadding(num, digit) {
		let pad = '';
		for (let i = 0; i < digit; i++) {
			pad += '0';
		}
		return (pad + num).slice(-digit);
	}

	hasError(errors, key) {
		return errors[key] !== '';
	}

	extractFileExtension(fileName) {
		let tmp = fileName.split('.');
		return tmp[tmp.length - 1];
	}

	createCsvContents(data, delim = ',', isQuote = false) {
		let csv = '\ufeff';

		for (let row in data) {
			let line = '';
			for (let col in data[row]) {
				if (!isQuote) {
					line += data[row][col] + delim;
				} else {
					line += `"${data[row][col]}"${delim}`;
				}
			}
			line = line.slice(0, -1);
			csv += line + '\n';
		}

		return csv;
	}

	downloadCSV(data, year, month, fileName, isQuote = false) {
		let csv = this.createCsvContents(data, ',', isQuote);
		let blob = new Blob([csv], { type: 'text/csv' })
		let link = document.createElement('a')
		link.href = window.URL.createObjectURL(blob)
		link.download = fileName
		link.click()
	}

	convertToHhalfWidthCharacter(str) {
		return str.replace(/[０-９]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) - 65248);
		})
		.replace(/[‐－―]/g, '-');
	}

	isObject(obj) {
		return Object.prototype.toString.call(obj) === "[object Object]";
	}

	ellipsis(str, max) {
		if (str.length <= max - 7) {
			return str;
		} else {
			return str.replace(/\r?\n/g, '').substr(0, max) + '...<span class="ellipsis">続きを見る</span>';
		}
	}

	getHostName() {
		let url = new URL(location.href);
		//this.log(url.host);
		//this.log(url.origin);
		return url.origin;
	}

	isSp() {
		return isMobile(window.navigator).any;
	}

	hyperlink(str) {
		let reg = new RegExp("((https?)(://[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+))", 'g');
		if (this.isApp()) {
			return str.replace(reg,'<a href="openbrowser:$1">$1</a>');
		} else {
			return str.replace(reg,'<a href="$1" target="_blank">$1</a>');
		}
	}
}

export default Utils
