import axios from 'axios'
import store from '../store'

class Api {
	constructor(host = null) {
		this.host = host || process.env.VUE_APP_API_ORIGIN;
	}

	get(endPoint, param = null, isAuth = false) {
		return this._exec('get', endPoint, param, isAuth);
	}

	post(endPoint, param = null, isAuth = false) {
		return this._exec('post', endPoint, param, isAuth);
	}

	put(endPoint, param = null, isAuth = false) {
		return this._exec('put', endPoint, param, isAuth);
	}

	patch(endPoint, param = null, isAuth = false) {
		return this._exec('patch', endPoint, param, isAuth);
	}

	delete(endPoint, param = null, isAuth = false) {
		return this._exec('delete', endPoint, { data: param }, isAuth);
	}

	_exec(method, endPoint, param = null, isAuth = false) {
		let data = param;
		let url = this.host + endPoint;
		let headers = isAuth ? { Authorization: `Bearer ${store.state.token}`, } : {};

		return axios({
			method: method,
			url: url,
			data: data,
			headers: headers,
		});
	}
}

export default Api
